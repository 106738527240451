import { tns } from "tiny-slider/src/tiny-slider";
import LazyLoad from "vanilla-lazyload";
var lazyLoad = new LazyLoad();
function sliderLazy() {
    lazyLoad.update();
}
export default function sliderLogements() {
    var slider_logements = document.getElementById("slider-logements");
    if (slider_logements !== null) {
        var slides = jQuery(".slider-logements").data("slides");
        console.log(slides);
        var sliderLogements = tns({
            container: ".slider-logements",
            slideBy: "page",
            items: 1,
            loop: true,
            autoplay: true,
            autoplayButtonOutput: false,
            controls: true,
            nav: true,
            center: false,
            gutter: 0,
            autoplayTimeout: 3000,
            controlsContainer: ".controls-logements",
            navPosition: "bottom",
            responsive: {
                1280: {
                    items: 2,
                },
            },
        });
        sliderLogements.events.on("transitionEnd", sliderLazy);
    }
}

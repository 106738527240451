import MicroModal from "micromodal";
import Cookies from "js-cookie";
import { tns } from "tiny-slider/src/tiny-slider";
function eventFire(el, etype) {
    if (el.fireEvent) {
        el.fireEvent("on" + etype);
    } else {
        var evObj = document.createEvent("Events");
        evObj.initEvent(etype, true, false);
        el.dispatchEvent(evObj);
    }
}

export default function init() {
    MicroModal.init();
    console.log(Cookies.get("modal-flash"));

    // if .home existis
    var slider_flash = document.getElementById("slider-flash");
    if (slider_flash !== null) {
        var sliderFlash = tns({
            container: ".slider-flash",
            slideBy: "page",
            items: 1,
            loop: true,
            autoplay: true,
            autoplayButtonOutput: false,
            controls: true,
            nav: true,
            center: false,
            autoHeight: true,
            gutter: 30,
            autoplayTimeout: 7000,
            controlsContainer: ".controls-flash",
            navContainer: ".nav-flash",
        });
    }

    if (jQuery("#js-infos").length > 0) {
        if (Cookies.get("modal-flash") != "1") {
            // refresh sliderFlash
            sliderFlash.refresh();
            MicroModal.show("modal-flash");
        }
        Cookies.set("modal-flash", "1", { expires: 1 });
    }
    jQuery("#js-infos").on("click", function () {
        console.log("click");
        sliderFlash.refresh();
        MicroModal.show("modal-flash");
    });

    jQuery("#js-infos-2").on("click", function () {
        jQuery('html, body').animate({
            scrollTop: jQuery('footer').offset().top
        }, 1000);
    });

    jQuery("#access-link").on("click", function () {
        eventFire(document.getElementById("uci_link"), "click");
    });

    jQuery("#js-menu-open, #js-menu-close").on("click", function () {
        console.log("click");
        jQuery("#js-menu-overlay").toggleClass("invisible");
        jQuery("#js-menu-overlay").toggleClass("opacity-25");
        jQuery("#js-menu-overlay").toggleClass("opacity-0");
        jQuery("#js-menu").toggleClass("-left-full");
        jQuery("#js-menu").toggleClass("left-0");
    });

    // Listes Depliables
    jQuery(".liste-depliable__title").on("click", function () {
        jQuery(this).parent().toggleClass("active");
        jQuery(this).next(".liste-depliable__content").slideToggle();
    });

    jQuery("span.childrens").on("click", function () {
        jQuery(this).parent().toggleClass("active");
        jQuery(this).next("ul").slideToggle();
    });

    // on hover

    jQuery(".user-dropdown")
        .on("mouseenter", function () {
            console.log("mouseenter");
            jQuery(".user-dropdown__content").toggleClass("invisible");
            jQuery(".user-dropdown__content").toggleClass("opacity-0");
        })
        .on("mouseleave", function () {
            console.log("mouseleave");
            jQuery(".user-dropdown__content").toggleClass("invisible");
            jQuery(".user-dropdown__content").toggleClass("opacity-0");
        });
}

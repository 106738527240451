import MicroModal from "micromodal";
import LazyLoad from "vanilla-lazyload";

function ajax(date) {
  jQuery.ajax({
    url: window.origin + "/wp/wp-admin/admin-ajax.php",
    data: {
      action: "home_agenda",
      date: date,
    },
    type: "POST",
    beforeSend: function () {
      jQuery(".modal__content").html("");
      jQuery(".loader").show();
    },
    success: function (data) {
      jQuery(".loader").hide();
      jQuery(".modal__content").html(data);
    },
  });
  return false;
}
export default function homeAgenda() {
  //load Micromodal module
  MicroModal.init();
  const params = {
    action: "home_agenda",
    month: "",
  };
  var myCalendar = jsCalendar.new({
    target: document.getElementById("my_calendar"),
    language: "fr",
    navigatorPosition: "center",
    firstDayOfTheWeek: 2,
    zeroFill: false,
    monthFormat: "month YYYY",
  });
  myCalendar.select(dates);
  myCalendar.onDateClick(function (event, dateCal) {
    dateCal = dateCal.toLocaleDateString("en-GB");
    var classes = event.target.className;
    if (classes.includes("jsCalendar-selected")) {
      var lazyLoad = new LazyLoad();
      jQuery(".date_modal").text(dateCal);
      ajax(dateCal);
      MicroModal.show("modal-calendar");
    }
  });
}

import { tns } from "tiny-slider/src/tiny-slider";
import LazyLoad from "vanilla-lazyload";
var lazyLoad = new LazyLoad();
function sliderLazy() {
    lazyLoad.update();
}
export default function sliderActualites() {
    var slider_actualites_site1 = document.getElementById(
        "slider-actualites_1",
    );
    var slider_actualites = document.getElementById("slider-actualites");
    if (slider_actualites !== null) {
        var sliderActualites = tns({
            container: ".slider-actualites",
            slideBy: "page",
            items: 1,
            loop: false,
            autoplay: false,
            autoplayButtonOutput: false,
            controls: true,
            nav: false,
            center: false,
            autoHeight: false,
            gutter: 30,
            autoplayTimeout: 3000,
            controlsContainer: ".controls-actus-home",
            navPosition: "bottom",
            responsive: {
                768: {
                    items: 2,
                },
                1280: {
                    items: 1,
                },
            },
        });
        sliderActualites.events.on("transitionEnd", sliderLazy);
    }
    if (slider_actualites_site1 !== null) {
        var sliderActualites = tns({
            container: ".slider-actualites_1",
            slideBy: "page",
            items: 1,
            loop: true,
            autoplay: true,
            autoplayButtonOutput: false,
            controls: false,
            nav: false,

            autoHeight: true,
            gutter: 30,
            autoplayTimeout: 3000,
            // controlsContainer: ".controls-actus-home",
            navPosition: "bottom",
            responsive: {
                768: {
                    items: 2,
                },
                1280: {
                    items: 3,
                },
            },
        });
        sliderActualites.events.on("transitionEnd", sliderLazy);
    }

    jQuery("#actu-cse").on("click", function () {
        jQuery(this).toggleClass("active");
        if (jQuery(".slider-container").hasClass("hidden") === false) {
            jQuery(".slider-container").toggleClass("hidden");
        } // if actualites-home__posts-patrimoine is hidden, dont toggle it
        if (
            jQuery(".actualites-home__posts-patrimoine").hasClass("hidden") ===
            false
        ) {
            jQuery(".actualites-home__posts-patrimoine").toggleClass("hidden");
        }

        jQuery(".actualites-home__posts").toggleClass("hidden");
        jQuery(".actualites-home__posts").toggleClass("flex");
    });
    jQuery("#actu-partenaires").on("click", function () {
        jQuery(this).toggleClass("active");
        if (jQuery(".actualites-home__posts").hasClass("hidden") === false) {
            jQuery(".actualites-home__posts").toggleClass("hidden");
        }
        if (jQuery(".slider-container").hasClass("hidden") === false) {
            jQuery(".slider-container").toggleClass("hidden");
        }
        jQuery(".actualites-home__posts-patrimoine").toggleClass("hidden");
        jQuery(".actualites-home__posts-patrimoine").toggleClass("flex");
    });
    jQuery("#actu-sem4v").on("click", function () {
        jQuery(this).toggleClass("active");
        if (
            jQuery(".actualites-home__posts-patrimoine").hasClass("hidden") ===
            false
        ) {
            jQuery(".actualites-home__posts-patrimoine").toggleClass("hidden");
        }
        if (jQuery(".actualites-home__posts").hasClass("hidden") === false) {
            jQuery(".actualites-home__posts").toggleClass("hidden");
        }
        jQuery(".slider-container").toggleClass("hidden");
        sliderActualites.refresh();
    });
}

import LazyLoad from "vanilla-lazyload";
import init from "./common";
import homeAgenda from "./home-agenda";
import sliderLogements from "./slider-logements";
import sliderActualites from "./slider-actualites";
import { formInit } from "./logements-filter";
import initMap from "./osm";
init();
var lazyLoad = new LazyLoad();
console.log("home.js");

if (jQuery("body").hasClass("site-1")) {
    sliderLogements();
    sliderActualites();
}
if (jQuery("body").hasClass("site-2")) {
    sliderActualites();
    initMap("carte-patrimoine");
}
if (jQuery("body").hasClass("site-3")) {
    homeAgenda();
}

if (
    jQuery("body").hasClass("site-1") ||
    jQuery("body").hasClass("page-template-offres")
) {
    formInit();
}

import noUiSlider from "./utils/nouislider";
import * as L from "leaflet";
import LazyLoad from "vanilla-lazyload";
import initMap from "./osm";

export function formInit() {
  var rangePrix = document.getElementById("slider");
  jQuery(document).mouseup(function (e) {
    var container = jQuery(".typologies, .villes");

    // if the target of the click isn't the container nor a descendant of the container
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      jQuery(".dropdown").each(function (e) {
        if (!jQuery(this).hasClass("active")) {
          jQuery(this).toggleClass("active");
        }
      });
      container.find(".dropdown").toggleClass("active");
    }
  });
  jQuery(".typologies .placeholder, .villes .placeholder").on(
    "click",
    function () {
      jQuery(this).parent().find(".dropdown").toggleClass("active");
    }
  );
  var checked = {
    ville: [],
    typologie: [],
  };
  jQuery(".ville-input, .typologie-input").on("click", function () {
    var type = jQuery(this).attr("data-type");
    if (jQuery(this).prop("checked")) {
      checked[type].push(jQuery(this).val());
    } else {
      checked[type].splice(checked[type].indexOf(jQuery(this).val()), 1);
    }
    if (checked[type].length > 0) {
      jQuery(".badge-" + type).html(checked[type].length);
      jQuery(".badge-" + type).show();
    } else if (checked[type].length == 0) {
      jQuery(".badge-" + type).hide();
    }
  });

  noUiSlider.create(rangePrix, {
    start: [200, 1200],
    connect: false,

    orientation: "horizontal",
    range: {
      min: 0,
      max: 2000,
    },
    format: wNumb({
      prefix: "€ ",
      decimals: 0,
    }),
  });
  rangePrix.noUiSlider.on("update", function (values) {
    jQuery(".input-price-min").val(values[0]);
    jQuery(".input-price-max").val(values[1]);
  });
  if (jQuery("body").hasClass("page-template-offres")) {
    jQuery(".form").on("submit", function (e) {
      e.preventDefault();
      console.log($(this).find("form").serialize());
      jQuery.ajax({
        url: window.origin + "/wp/wp-admin/admin-ajax.php",
        data: {
          data: $(this).find("form").serialize(),
          action: "ajax_logements",
        },
        type: "POST",
        beforeSend: function () {
          jQuery(".offres-container").html("");
          jQuery(".loader").show();
        },
        success: function (data) {
          jQuery(".loader").hide();
          jQuery(".offres-container").html(data);
          const lazyLoad = new LazyLoad();
          initMap("single-carte");
        },
      });
      return false;
    });
  }
}

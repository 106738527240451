import * as L from "leaflet";
import LazyLoad from "vanilla-lazyload";
import "./fullscreen-leaflet";

export function createMarker(marker, latLong, layerGroup, postId) {
    marker.each(function () {
        const marker_content = jQuery(this).html();
        const postId = jQuery(this).data("id");
        console.log(postId);
        var picto = jQuery(this).data("picto") || "";
        if (picto !== "") {
            var customIcon = L.icon({
                iconUrl: picto,
                // Ajoutez d'autres options personnalisées ici, si nécessaire
            });
            var marker = L.marker(
                [jQuery(this).data("lat"), jQuery(this).data("lng")],
                {
                    icon: customIcon,
                },
            )
                .bindPopup(marker_content)
                .on("click", function (e) {
                    const lazyLoad = new LazyLoad();
                });
        } else {
            var marker = L.marker([
                jQuery(this).data("lat"),
                jQuery(this).data("lng"),
            ])
                .bindPopup(marker_content)
                .on("click", function (e) {
                    const lazyLoad = new LazyLoad();
                });
        }
        marker.on("mouseover", function (e) {
            this.openPopup();
            const lazyLoad = new LazyLoad();
        });
        marker.on("mouseout", function (e) {
            // this.closePopup();
            const lazyLoad = new LazyLoad();
        });
        var bounds = [jQuery(this).data("lat"), jQuery(this).data("lng")];
        layerGroup.addLayer(marker);
        latLong.push(bounds);
        if (postId) {
            jQuery(marker._icon).addClass("marker-" + postId);
        }
    });
}
export default function initMap($el) {
    const latLong = [];
    const Map = L.map($el, {
        scrollWheelZoom: false,
        fullscreenControl: true,
        fullscreenControlOptions: {
            position: "topleft",
        },
    }).setView(["45.7539397", "6.4106844"], 16);
    const layerGroup = L.layerGroup().addTo(Map);
    layerGroup.clearLayers();
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution:
            '© <a href="https://www.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    }).addTo(Map);

    createMarker(jQuery(".marker"), latLong, layerGroup);
    var $bounds = new L.LatLngBounds(latLong);
    Map.fitBounds($bounds);
    jQuery(".change-view").off("click");
    jQuery(".change-view").on("click", function () {
        jQuery(".change-view").each(function () {
            if (jQuery(this).hasClass("active")) {
                jQuery(this).removeClass("active");
            }
        });
        var view = jQuery(this).data("view");
        var view_hidden = jQuery(this).data("view-hidden");
        console.log(view);
        jQuery(this).toggleClass("active");
        jQuery("#" + view).toggleClass("hidden");
        jQuery("#" + view_hidden).toggleClass("hidden");
        Map.invalidateSize();
        Map.fitBounds($bounds);
    });

    jQuery(".fiche-patrimoine").on("mouseenter", function () {
        const postId = jQuery(this).data("id");
        // opacity: 0.5 to all marker except marker-{postId}

        jQuery(".leaflet-marker-icon").each(function () {
            jQuery(this).css("filter", "grayscale(1)");
            jQuery(this).css("opacity", "0");
        });
        jQuery(".leaflet-marker-icon.marker-" + postId).each(function () {
            jQuery(this).css("filter", "grayscale(0)");
            jQuery(this).css("opacity", "1");
        });
    });
    jQuery(".fiche-patrimoine").on("mouseleave", function () {
        const postId = jQuery(this).data("id");
        // opacity: 0.5 to all marker except marker-{postId}

        jQuery(".leaflet-marker-icon").each(function () {
            jQuery(this).css("filter", "grayscale(0)");
            jQuery(this).css("opacity", "1");
        });
    });
}
